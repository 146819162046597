<template>
  <select
    class="form-control"
    :value="sucursal"
    @change="$emit('update:sucursal', $event.target.value)"
    required
  >
    <option value="" selected disabled>{{ placeholder }}</option>
    <option value="0" v-if="all">Todos</option>
    <option
      v-for="_sucursal in sucursales"
      :key="_sucursal.id_sucursal"
      :value="_sucursal.id_sucursal"
    >
      {{ _sucursal.nombre }}
    </option>
  </select>
</template>

<script>
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2";
import { useApi } from "@/use/useApi";

export default defineComponent({
  name: "SelectSucursal",
  props: {
    sucursal: {
      type: String,
      default: "",
    },
    all: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Seleccionar Sucursal",
    },
  },
  setup() {
    const { loading, make } = useApi();
    const sucursales = ref([]);

    async function getSucursales() {
      loading.message = "Obteniendo sucursales";

      let { data } = await make("generales/get-sucursales", {});

      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          sucursales.value = data.sucursales;
        }
      }
    }

    getSucursales();

    return { sucursales };
  },
});
</script>