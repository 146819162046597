<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">
                    Bitácora de empleados
                  </h3>
                </div>
              </div>
            </div>
            <!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li class="nav-item">
                        <a class="nav-link active" href="#"
                          ><em class="icon ni ni-user-circle"></em
                          ><span>General</span></a
                        >
                      </li>
                    </ul>
                    <!-- .nav-tabs -->

                    <TableGrid
                      :rows="rows"
                      :filters="filters"
                      :total="total"
                      @update="update"
                    >
                      <template #filters>
                        <div class="card-tools between-start overflow-auto">
                          <div
                            v-for="filter in filters"
                            class="mr-3"
                            :key="filter.key"
                          >
                            <input
                              v-if="filter.type === 'date'"
                              type="date"
                              class="form-control w-min-100px"
                              :placeholder="filter.label"
                              autocomplete="new"
                              v-model.lazy="filter.value"
                            />
                            <input
                              v-if="filter.type === 'text'"
                              type="text"
                              class="form-control w-min-100px"
                              :placeholder="filter.label"
                              autocomplete="new"
                              v-model.lazy="filter.value"
                            />
                            <SelectSucursal
                              v-if="filter.label === 'Sucursal'"
                              class="form-control w-min-150px"
                              v-model:sucursal="filter.value"
                              :all="true"
                              placeholder="Sucursal"
                            >
                            </SelectSucursal>
                          </div>
                        </div>
                      </template>
                      <template #header>
                        <div class="nk-tb-col">
                          <span class="sub-text">ID</span>
                        </div>
                        <div class="nk-tb-col">
                          <span class="sub-text">Hora de entrada</span>
                        </div>
                        <div class="nk-tb-col tb-col-sm">
                          <span class="sub-text">Hora de salida</span>
                        </div>
                        <div class="nk-tb-col">
                          <span class="sub-text">Sucursal</span>
                        </div>
                        <div class="nk-tb-col">
                          <span class="sub-text">Empleado</span>
                        </div>
                        <div class="nk-tb-col">
                          <span class="sub-text">Evidencias</span>
                        </div>
                        <div class="nk-tb-col">
                          <span class="sub-text">Checkout</span>
                        </div>
                      </template>
                      <template v-slot:row="{ row }">
                        <div class="nk-tb-col">
                          <span>{{ row.id_bitacora }}</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>
                            {{
                              $filters.fecha(
                                row.hora_entrada,
                                "DD, MMMM [de] YYYY hh:mm:ss A"
                              )
                            }}
                          </span>
                        </div>
                        <div class="nk-tb-col">
                          <span>
                            {{
                              row.hora_salida == null ? 'No se ha registrado' : $filters.fecha(
                                row.hora_salida,
                                "DD, MMMM [de] YYYY hh:mm:ss A"
                              )
                            }}
                          </span>
                        </div>
                        <div class="nk-tb-col">
                          <span>{{ row.nombre_sucursal }}</span>
                        </div>
                        <div class="nk-tb-col">
                          <span>{{ row.empleado }}</span>
                        </div>
                        <div class="nk-tb-col">
                          <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dlgEvidencias" @click="getLogEvidence(row)">Mostrar evidencias</button>
                        </div>
                        <div class="nk-tb-col">
                          <button v-if="row.hora_salida == null" type="button" class="btn btn-danger" @click="checkout(row)">Checkout</button>
                        </div>
                      </template>
                    </TableGrid>

                    <figure class="highcharts-figure">
                      <highcharts :options="grafica" />
                      <p class="highcharts-description">
                        En la gráfica podrás observar el número de registros
                        realizados a las sucursales dependiendo del rango de
                        fechas seleccionado.
                      </p>
                    </figure>
                  </div>
                  <!-- .card-content -->
                </div>
                <!-- .card-aside-wrap -->
              </div>
              <!-- .card -->
            </div>
            <!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->

    <div class="modal fade" tabindex="-1" id="dlgEvidencias">
      <div class="modal-dialog" role="document" style="max-width: 50% !important">
        <div class="modal-content">
          <a href="#" class="close" data-dismiss="modal" aria-label="Close">
            <em class="icon ni ni-cross"></em>
          </a>
          <div class="modal-header">
            <h5 class="modal-title">Evidencias</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                v-for="evidence in evidences"
                :key="evidence"
                class="col-lg-3 col-md-3 col-sm-6 col-xs-12"
              >
                <div class="card card-bordered product-card" style="margin-bottom: 15px">
                  <div class="product-thumb">
                    <img
                      class="card-img-top"
                      :src="evidence.archivo.url"
                      :alt="evidence.nombre"
                    />
                  </div>
                  <div class="card-inner text-center">
                    <ul class="product-tags"><li>{{ evidence.comentarios }}</li></ul>
                    <div v-if="evidence.latitude != null && evidence.longitude != null" class="team-view"><a :href="'https://maps.google.com/?q=' + evidence.latitude + ',' + evidence.longitude" target="_blank" class="btn btn-round btn-outline-light w-150px"><span>Ver mapa</span></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import { reactive, ref, toRefs } from "vue";
import { useApi } from "@/use/useApi";
import Swal from "sweetalert2";
import TableGrid from "@/components/TableGrid";
import SelectSucursal from "@/components/globales/SelectSucursal";
import { Chart } from "highcharts-vue";

export default {
  name: "Editar Plataforma",
  components: { AppView, TableGrid, SelectSucursal, highcharts: Chart },
  setup() {
    const { loading, make } = useApi();

    const state = reactive({
      rows: [],
      total: 1,
    });

    const model = reactive({
      data: {
        fecha_inicio: new Date().toISOString().slice(0, 10),
        fecha_fin: new Date().toISOString().slice(0, 10),
      },
      inited: false,
    });

    const filters = ref([
      {
        key: "bitacora.hora_entrada",
        label: "Fecha de inicio",
        value: new Date().toISOString().slice(0, 10),
        type: "date",
      },
      {
        key: "bitacora.hora_salida",
        label: "Fecha de fin",
        value: new Date().toISOString().slice(0, 10),
        type: "date",
      },
      {
        key: "CONCAT( empleados.nombre, ' ', IFNULL(empleados.apellido_paterno, ''), ' ', IFNULL(empleados.apellido_materno, '') ) ",
        label: "Nombre del empleado",
        value: "",
        type: "text",
      },
      {
        key: "bitacora.id_sucursal",
        label: "Sucursal",
        value: "",
        type: "select",
      },
    ]);

    const grafica = ref({
      lang: {
        viewFullscreen: "Ver en pantalla completa",
        printChart: "Imprimir gráfica",
        downloadPNG: "Descargar en PNG",
        downloadJPEG: "Descargar en JPEG",
        downloadPDF: "Descargar en PDF",
        downloadSVG: "Descargar en SVG",
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "No. de registros por servicio",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y}</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Servicios",
          colorByPoint: true,
          data: [],
        },
      ],
    });

    const evidences = ref([]);

    const table = ref();

    async function update(config) {
      table.value = config;
      loading.message = "Obteniendo información";
      let { data } = await make("bitacora/get", config);
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          state.rows = data.rows;
          state.total = data.total;

          grafica.value.series[0].data =
            data.rows_per_service == null ? [] : data.rows_per_service;
        }
      }
    }

    async function getLogEvidence(row) {
      loading.message = "Obteniendo información";
      let { data } = await make("bitacora/getLogEvidence", {
        row: row
      });
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          evidences.value = data.evidencias;
        }
      }
    }

    async function checkout(row) {
      loading.message = "Cerrando la bitácora";
      let { data } = await make("bitacora/checkout", {
        row: row
      });
      if (data === null) {
        Swal.fire(
          "Error",
          "Ocurrió un error al conectarse con el servidor",
          "error"
        );
      } else {
        if (data.error) {
          Swal.fire("Error", data.message, "error");
        } else {
          Swal.fire("Correcto", data.message, "success");
          update(table.value);
        }
      }
    }

    return {
      ...toRefs(state),
      ...toRefs(model),
      loading,
      filters,
      update,
      grafica,
      getLogEvidence,
      evidences,
      checkout,
    };
  },
};
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}
.profile-ud-label {
  width: 130px;
  align-items: start;
  margin-top: 0.5em;
}
.profile-ud-value {
  line-height: 2.5em;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

input[type="number"] {
  min-width: 50px;
}

.highcharts-description {
  text-align: center;
}
</style>